<template>
  <IngestionCard />
</template>

<script>
import IngestionCard from '@/components/IngestionCard';

export default {
  components: {
    IngestionCard
  }
};
</script>
